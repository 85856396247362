import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import i18n from '@/i18n';
import { publicModule } from '@/store/modules/public';
import { detailsModule } from '@/store/modules/details';
import { supportModule } from '@/store/modules/support';
import { aboutModule } from '@/store/modules/about';
Vue.use(VueRouter);
var router = new VueRouter({
    mode: 'history',
    base: '/',
    scrollBehavior: function (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                selector: to.hash
            };
        }
        return { x: 0, y: 0 };
    },
    routes: routes
});
router.beforeEach(function (to, from, next) {
    switch (to.name) {
        case 'product':
            detailsModule.setProductID(parseInt(to.params.id, 10));
            break;
        case 'productDetails':
            detailsModule.getProductObj(parseInt(to.params.id, 10));
            break;
        case 'app':
            detailsModule.setApplicationID(parseInt(to.params.id, 10));
            break;
        case 'appDetails':
            detailsModule.getApplicationObj(parseInt(to.params.id, 10));
            break;
        case 'service':
            supportModule.setServicetID(parseInt(to.params.id, 10));
            break;
        case 'news':
            detailsModule.setNewsID(parseInt(to.params.id, 10));
            break;
        case 'newsList':
            detailsModule.getNewsList({
                page: 1,
                rows: 6,
                id: parseInt(to.params.id, 10),
                stick: ''
            });
            break;
        case 'credit':
            aboutModule.setCertificateID(parseInt(to.params.id, 10));
            break;
        case 'recruit':
            aboutModule.setRecruitID(parseInt(to.params.id, 10));
            break;
        default:
    }
    if (to.path !== '/404') {
        if (to.params.lang === 'cn' || to.params.lang === 'en') {
            if (to.params.lang === 'en') {
                if (to.name === 'recruit' || to.name === 'job') {
                    next('/404');
                }
            }
            publicModule.setLang(to.params.lang);
            i18n.locale = to.params.lang;
            next();
        }
        else {
            next('/404');
        }
    }
    else {
        next();
    }
});
export default router;
