var message = {
    title: '售后服务承诺',
    saleTitle: '售后服务中心',
    subSaleTitle: 'AFTER-SALES SERVICE CENTER',
    tel: '技术支持热线',
    sealEmail: '售后邮箱',
    contact: '联络处',
    linkman: '联系人',
    phone: '电话',
    email: '邮箱',
    address: '地址'
};
export default message;
