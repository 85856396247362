import { __read, __spread } from "tslib";
import Layout from '@/views/Layout.vue';
import home from './modules/home';
import productCenter from './modules/product-center';
import industryApplication from './modules/industry-application';
import serviceSupport from './modules/service-support';
import newsCenter from './modules/news-center';
import aboutUs from './modules/about-us';
import disclaimer from './modules/disclaimer';
import search from './modules/search';
export default [
    {
        path: '/',
        redirect: 'cn/home'
    },
    {
        path: '/cn',
        redirect: 'cn/home'
    },
    {
        path: '/en',
        redirect: 'en/home'
    },
    {
        path: '*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: function () { return import('@/views/404.vue'); }
    },
    {
        path: '/layout',
        name: 'layout',
        component: Layout,
        children: __spread(home, productCenter, industryApplication, serviceSupport, newsCenter, aboutUs, disclaimer, search)
    }
];
