import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import Meta from 'vue-meta';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/base.scss';
Vue.use(Meta);
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
    router: router,
    store: store,
    i18n: i18n,
    render: function (h) { return h(App); }
}).$mount('#app');
