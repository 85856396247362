var message = {
    department: '部门',
    nature: '职位性质',
    place: '工作地点',
    number: '招聘人数',
    title: '岗位申请',
    options: [
        {
            label: '姓名',
            name: 'name'
        },
        {
            label: '性别',
            name: 'gender',
            options: [
                {
                    lable: '男',
                    value: '男'
                },
                {
                    lable: '女',
                    value: '女'
                }
            ]
        },
        {
            label: '电话',
            name: 'phone'
        },
        {
            label: '邮箱',
            name: 'email'
        }
    ],
    label: '附件',
    name: 'banner',
    button: '请选择附件',
    hint: '仅支持WORD|PDF格式，文件需小于200K',
    message: '该输入项为必填项',
    uploading: '上传中...',
    uploadSuccess: '上传成功',
    submit: '提交',
    reset: '重置',
    backspace: "不能包含空格",
    phonephabet: "电话格式不正确",
    email: "请输入有效的邮箱",
    uploadlimit: "上传文件大小最大不能超过200kb",
};
export default message;
