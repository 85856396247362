import { __assign } from "tslib";
import request from '@/utils/axios';
import { publicModule } from '@/store/modules/public';
export function getRecruit(params) {
    return request({
        method: 'get',
        url: 'aboutus/recruit',
        params: __assign(__assign({}, params), { lang: publicModule.lang })
    }).then(function (result) {
        if (result.data.code === 0) {
            return result.data;
        }
        return null;
    });
}
export function getRecruitPosition(params) {
    return request({
        method: 'get',
        url: 'aboutus/recruit/position',
        params: __assign(__assign({}, params), { lang: publicModule.lang })
    }).then(function (result) {
        if (result.data.code === 0) {
            return result.data;
        }
        return null;
    });
}
export function increaseViewCount(data) {
    return request({
        method: 'post',
        url: 'aboutus/recruit/view_count/increase',
        data: data,
        params: { lang: publicModule.lang }
    }).then(function (result) {
        if (result.data.code === 0) {
            return result.data;
        }
        return null;
    });
}
