import { __decorate, __extends } from "tslib";
import { Module, Mutation, getModule, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
var Public = (function (_super) {
    __extends(Public, _super);
    function Public() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.innerWidth = 0;
        _this.innerHeight = 0;
        _this.lang = 'cn';
        return _this;
    }
    Public.prototype.setInnerWidth = function (val) {
        this.innerWidth = val;
    };
    Public.prototype.setInnerHeight = function (val) {
        this.innerHeight = val;
    };
    Public.prototype.setLang = function (val) {
        this.lang = val;
    };
    __decorate([
        Mutation
    ], Public.prototype, "setInnerWidth", null);
    __decorate([
        Mutation
    ], Public.prototype, "setInnerHeight", null);
    __decorate([
        Mutation
    ], Public.prototype, "setLang", null);
    Public = __decorate([
        Module({ name: 'public', namespaced: true, dynamic: true, store: store })
    ], Public);
    return Public;
}(VuexModule));
export default Public;
export var publicModule = getModule(Public);
