import { __assign, __decorate, __extends } from "tslib";
import { Module, Mutation, Action, getModule, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { getCompanyInfo, getCompanyContact } from '@/api/about/company';
import { getOffice } from '@/api/about/office';
import { getHistory } from '@/api/about/history';
import { getCredit } from '@/api/about/credit';
import { getVideo } from '@/api/about/video';
import { getRecruit, increaseViewCount } from '@/api/about/recruit';
import moment from 'moment';
var About = (function (_super) {
    __extends(About, _super);
    function About() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.companyInfo = {};
        _this.contactInfo = {};
        _this.historyParams = {};
        _this.certificateID = 38;
        _this.certificateList = [];
        _this.certificateTotal = 0;
        _this.videoList = [];
        _this.recruitID = 43;
        _this.recruitInfo = {};
        _this.jobInfo = {};
        _this.headquartersInfo = {};
        return _this;
    }
    About.prototype.setCompanyInfo = function (val) {
        this.companyInfo = val;
    };
    About.prototype.setContactInfo = function (val) {
        this.contactInfo = val;
    };
    About.prototype.setHistoryParams = function (val) {
        this.historyParams = val;
    };
    About.prototype.setCertificateID = function (val) {
        this.certificateID = val;
    };
    About.prototype.setCertificateList = function (val) {
        this.certificateList = val;
    };
    About.prototype.setCertificateTotal = function (val) {
        this.certificateTotal = val;
    };
    About.prototype.setVideoList = function (val) {
        this.videoList = val;
    };
    About.prototype.setRecruitID = function (val) {
        this.recruitID = val;
    };
    About.prototype.setRecruitInfo = function (val) {
        this.recruitInfo = val;
    };
    About.prototype.setJobInfo = function (val) {
        this.jobInfo = val;
    };
    About.prototype.setHeadquartersInfo = function (val) {
        this.headquartersInfo = val;
    };
    About.prototype.getCompanyInfo = function () {
        var _this = this;
        return getCompanyInfo({}).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                obj[item.key] = item;
            });
            _this.setCompanyInfo(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    About.prototype.getContactInfo = function () {
        var _this = this;
        return getOffice({
            page: 1,
            rows: 999,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                obj[item.id] = item;
            });
            _this.setContactInfo(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    About.prototype.getHistoryParams = function () {
        var _this = this;
        return getHistory({
            page: 1,
            rows: 999,
            sort: 'id',
            order: 'asc'
        }).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                var year = moment(item.happen_date).format('YYYY');
                var month = moment(item.happen_date).format('MM月');
                if (obj[year]) {
                    obj[year].push(__assign(__assign({}, item), { month: month }));
                }
                else {
                    obj[year] = [__assign(__assign({}, item), { month: month })];
                }
            });
            _this.setHistoryParams(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    About.prototype.getCertificateList = function (_a) {
        var _this = this;
        var page = _a.page, rows = _a.rows, id = _a.id;
        return getCredit({
            page: page,
            rows: rows,
            category_id: id,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            _this.setCertificateList(result.rows);
            _this.setCertificateTotal(result.total);
            return result;
        }).
            catch(function (err) { return err; });
    };
    About.prototype.getVideoList = function () {
        var _this = this;
        return getVideo({
            page: 1,
            rows: 999,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            _this.setVideoList(result.rows);
            return result;
        }).
            catch(function (err) { return err; });
    };
    About.prototype.getRecruitInfo = function () {
        var _this = this;
        return getRecruit({
            page: 1,
            rows: 999,
            visible: 1,
            sort: 'id',
            order: 'desc'
        }).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                if (obj[item.category_id]) {
                    obj[item.category_id].push(item);
                }
                else {
                    obj[item.category_id] = [item];
                }
            });
            _this.setRecruitInfo(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    About.prototype.getJobInfo = function (id) {
        var _this = this;
        getRecruit({
            id: id,
            visible: 1
        }).then(function (result) {
            _this.setJobInfo(result.rows[0]);
            return result.rows[0];
        }).
            catch(function (err) { return err; });
    };
    About.prototype.getHeadquartersInfo = function () {
        var _this = this;
        return getCompanyContact({}).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                obj[item.key] = item;
            });
            _this.setHeadquartersInfo(obj);
            return obj;
        });
    };
    About.prototype.increaseViewCount = function (id) {
        return increaseViewCount({ id: id }).then(function (result) { return result; });
    };
    __decorate([
        Mutation
    ], About.prototype, "setCompanyInfo", null);
    __decorate([
        Mutation
    ], About.prototype, "setContactInfo", null);
    __decorate([
        Mutation
    ], About.prototype, "setHistoryParams", null);
    __decorate([
        Mutation
    ], About.prototype, "setCertificateID", null);
    __decorate([
        Mutation
    ], About.prototype, "setCertificateList", null);
    __decorate([
        Mutation
    ], About.prototype, "setCertificateTotal", null);
    __decorate([
        Mutation
    ], About.prototype, "setVideoList", null);
    __decorate([
        Mutation
    ], About.prototype, "setRecruitID", null);
    __decorate([
        Mutation
    ], About.prototype, "setRecruitInfo", null);
    __decorate([
        Mutation
    ], About.prototype, "setJobInfo", null);
    __decorate([
        Mutation
    ], About.prototype, "setHeadquartersInfo", null);
    __decorate([
        Action
    ], About.prototype, "getCompanyInfo", null);
    __decorate([
        Action
    ], About.prototype, "getContactInfo", null);
    __decorate([
        Action
    ], About.prototype, "getHistoryParams", null);
    __decorate([
        Action
    ], About.prototype, "getCertificateList", null);
    __decorate([
        Action
    ], About.prototype, "getVideoList", null);
    __decorate([
        Action
    ], About.prototype, "getRecruitInfo", null);
    __decorate([
        Action
    ], About.prototype, "getJobInfo", null);
    __decorate([
        Action
    ], About.prototype, "getHeadquartersInfo", null);
    __decorate([
        Action
    ], About.prototype, "increaseViewCount", null);
    About = __decorate([
        Module({ name: 'about', namespaced: true, dynamic: true, store: store })
    ], About);
    return About;
}(VuexModule));
export default About;
export var aboutModule = getModule(About);
