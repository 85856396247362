import { __assign } from "tslib";
import request from '@/utils/axios';
import { publicModule } from '@/store/modules/public';
export function getCredit(params) {
    return request({
        method: 'get',
        url: 'aboutus/credit',
        params: __assign(__assign({}, params), { lang: publicModule.lang })
    }).then(function (result) {
        if (result.data.code === 0) {
            return result.data;
        }
        return null;
    });
}
