var message = {
    title: '无线智慧视觉产品及系统方案提供商',
    watch: '了解公司情况',
    play: '查看品牌视频',
    companyName: '安徽清新互联信息科技有限公司',
    learnMore: '了解更多',
    companyCulture: '企业文化',
    subCompanyCulture: 'Corporate Culture',
    mission: '使命',
    orientation: '愿景',
    values: '价值观',
    culture: '精神',
    developmentHistory: '发展历程',
    subDevelopmentHistory: 'Development History',
    honoraryCertificate: '荣誉资质',
    subHonoraryCertificate: 'Honorary Certificate',
    enterpriseHonor: '企业荣誉',
    systemCertificate: '企业资质',
    patentCertificate: '专利证书',
    copyright: '著作权',
    productTesting: '产品检测',
    brandImage: '企业视频',
    subBrandImage: '点击查看更多企业视频',
    contactUs: '联系我们',
    subContactUs: '全国各地10余个联络处',
    partners: '招贤纳士',
    subPartners: '点击开始你的职业生涯'
};
export default message;
