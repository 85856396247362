var message = {
    list: [
        {
            title: '关于我们',
            list: [
                {
                    title: '了解清新',
                    name: 'aboutus',
                    id: '38'
                },
                {
                    title: '荣誉资质',
                    name: 'credit',
                    id: '38'
                },
                {
                    title: '发展历程',
                    name: 'aboutus',
                    id: '38',
                    hash: '#37'
                },
                {
                    title: '加入清新',
                    name: 'recruit',
                    id: '43'
                }
            ]
        },
        {
            title: '服务支持',
            list: [
                {
                    title: '客户服务',
                    name: 'service',
                    id: '15'
                },
                {
                    title: '下载专区',
                    name: 'service',
                    id: '16'
                },
                {
                    title: '投诉建议',
                    name: 'service',
                    id: '18'
                },
                {
                    title: '维修查询',
                    name: 'service',
                    id: '48'
                }
            ]
        },
        {
            title: '新闻中心',
            list: [
                {
                    title: '公司动态',
                    name: 'news',
                    id: '20'
                },
                {
                    title: '新品资讯',
                    name: 'newsList',
                    id: '21'
                },
                {
                    title: '成功应用',
                    name: 'newsList',
                    id: '22'
                },
                {
                    title: '展会信息',
                    name: 'newsList',
                    id: '23'
                },
                {
                    title: '业界资讯',
                    name: 'newsList',
                    id: '24'
                }
            ]
        },
        {
            title: '联系我们',
            list: [
                {
                    title: '总机：0551-65725390'
                },
                {
                    title: '营销热线：0551-65725772'
                },
                {
                    title: '客服热线：0551-65725392'
                },
                {
                    title: '邮箱：sales@tsinglink.com'
                },
                {
                    title: '安徽省合肥市高新区创新大道2800号'
                }
            ]
        }
    ],
    weChat: '官方微信',
    license: 'https://zzlz.gsxt.gov.cn/businessCheck/' +
        'verifKey.do?showType=p&serial=913401003950868800-' +
        'SAIC_CHECK_1000209134010039508688001565264171320&' +
        'signData=MEQCIBUT8c2YdkNRSUfEcMg5qYRmLLgKCtLkCAy1rdTEXYUEAiDq+' +
        'hznRDOO7RxoBY8apoQyrr+TKoRl22tg0w9uVj+v7Q==',
    record: 'https://beian.miit.gov.cn',
    record2: 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34019202002026',
    business: [
        '电子营业执照',
        '© 2014-2021 安徽清新互联信息科技有限公司 版权所有',
        '皖B2-20150070-1',
        '号',
        '网站地图',
        '免责声明',
        '皖公网安备 34019202002026号'
    ],
    link: {
        title: '友情链接：',
        list: [
            {
                title: '中安协',
                href: 'http://xh.21csp.com.cn/'
            },
            {
                title: '中国安防行业网',
                href: 'http://www.21csp.com.cn/'
            },
            {
                title: '安徽省安防协会',
                href: 'http://www.aspia.cn/'
            },
            {
                title: 'CPS中安网',
                href: 'http://www.cps.com.cn/'
            },
            {
                title: '中国安防展览网',
                href: 'http://www.afzhan.com/'
            },
            {
                title: 'Made-in-China',
                href: 'https://www.made-in-china.com/'
            }
        ]
    }
};
export default message;
