import { __decorate, __extends } from "tslib";
import { Module, Mutation, Action, getModule, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { getDocument } from '@/api/support/document';
import { getServiceInfo } from '@/api/support/service';
import { getMaintain } from '@/api/support/maintain';
import md5 from 'js-md5';
var Support = (function (_super) {
    __extends(Support, _super);
    function Support() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceID = 15;
        _this.documentParams = {};
        _this.serviceInfo = [];
        _this.maintainRecord = [];
        _this.maintainRecordTotal = 0;
        return _this;
    }
    Support.prototype.setServicetID = function (val) {
        this.serviceID = val;
    };
    Support.prototype.setDocumentParams = function (val) {
        this.documentParams = val;
    };
    Support.prototype.setServiceInfo = function (val) {
        this.serviceInfo = val;
    };
    Support.prototype.setMaintainRecord = function (val) {
        this.maintainRecord = val;
    };
    Support.prototype.setMaintainRecordTotal = function (val) {
        this.maintainRecordTotal = val;
    };
    Support.prototype.getDocumentParams = function () {
        var _this = this;
        return getDocument({
            page: 1,
            rows: 999,
            visible: 1,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                if (item.visible === 1) {
                    if (obj[item.category_id]) {
                        obj[item.category_id].push(item);
                    }
                    else {
                        obj[item.category_id] = [item];
                    }
                }
            });
            _this.setDocumentParams(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    Support.prototype.getServiceInfo = function () {
        var _this = this;
        return getServiceInfo({}).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                obj[item.key] = item;
            });
            _this.setServiceInfo(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    Support.prototype.getMaintainRecord = function (_a) {
        var _this = this;
        var page = _a.page, rows = _a.rows, mobile = _a.mobile, password = _a.password;
        return getMaintain({
            page: page,
            rows: rows,
            mobile: mobile,
            password: md5(password)
        }).then(function (result) {
            _this.setMaintainRecord(result.rows);
            _this.setMaintainRecordTotal(result.total);
            return result;
        }).
            catch(function (err) { return err; });
    };
    __decorate([
        Mutation
    ], Support.prototype, "setServicetID", null);
    __decorate([
        Mutation
    ], Support.prototype, "setDocumentParams", null);
    __decorate([
        Mutation
    ], Support.prototype, "setServiceInfo", null);
    __decorate([
        Mutation
    ], Support.prototype, "setMaintainRecord", null);
    __decorate([
        Mutation
    ], Support.prototype, "setMaintainRecordTotal", null);
    __decorate([
        Action
    ], Support.prototype, "getDocumentParams", null);
    __decorate([
        Action
    ], Support.prototype, "getServiceInfo", null);
    __decorate([
        Action
    ], Support.prototype, "getMaintainRecord", null);
    Support = __decorate([
        Module({ name: 'support', namespaced: true, dynamic: true, store: store })
    ], Support);
    return Support;
}(VuexModule));
export default Support;
export var supportModule = getModule(Support);
