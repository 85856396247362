export default [
    {
        path: '/:lang/news/:id',
        name: 'news',
        props: true,
        meta: {
            title: '新闻中心'
        },
        component: function () { return import('@/views/NewsCenter.vue'); }
    },
    {
        path: '/:lang/news/list/:id',
        name: 'newsList',
        props: true,
        meta: {
            title: '新闻列表'
        },
        component: function () { return import('@/views/NewsList.vue'); }
    },
    {
        path: '/:lang/news/detail/:id',
        name: 'newsDetails',
        props: true,
        meta: {
            title: '新闻详情'
        },
        component: function () { return import('@/views/NewsDetails.vue'); }
    }
];
