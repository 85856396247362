var message = {
    options: {
        features: 'Features',
        specifications: 'Specifications',
        applications: 'Applications',
        related: 'Related',
        brochure: 'Brochure'
    },
    recommend: 'Product recommendation'
};
export default message;
