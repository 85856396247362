import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var App = (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function (createElement) {
        return createElement('div', {
            attrs: {
                id: 'app'
            }
        }, [createElement('router-view')]);
    };
    App = __decorate([
        Component
    ], App);
    return App;
}(Vue));
export default App;
