var message = {
    list: [
        {
            title: 'About Us',
            list: [
                {
                    title: 'Know Us',
                    name: 'aboutus',
                    id: '38'
                },
                {
                    title: 'Credit',
                    name: 'credit',
                    id: '38'
                },
                {
                    title: 'History',
                    name: 'aboutus',
                    id: '38',
                    hash: '#37'
                },
                {
                    title: 'Join Us',
                    name: 'recruit',
                    id: '43'
                }
            ]
        },
        {
            title: 'Support',
            list: [
                {
                    title: 'Customer Service',
                    name: 'service',
                    id: '15'
                },
                {
                    title: 'Download',
                    name: 'service',
                    id: '16'
                },
                {
                    title: 'Complaint',
                    name: 'service',
                    id: '18'
                },
                {
                    title: 'Maintain',
                    name: 'service',
                    id: '48'
                }
            ]
        },
        {
            title: 'News',
            list: [
                {
                    title: 'Company News',
                    name: 'news',
                    id: '20'
                },
                {
                    title: 'New Product',
                    name: 'newsList',
                    id: '21'
                },
                {
                    title: 'Successful Application',
                    name: 'newsList',
                    id: '22'
                },
                {
                    title: 'Exhibition Information',
                    name: 'newsList',
                    id: '23'
                }
            ]
        },
        {
            title: 'Contact Us',
            list: [
                {
                    title: 'General Number：0551-65725390'
                },
                {
                    title: 'Marketing Hotline：0551-65725772'
                },
                {
                    title: 'Customer Hotline：0551-65725392'
                },
                {
                    title: 'Email：sales@tsinglink.com'
                },
                {
                    title: 'Block A J1 Building, Innovation Industry Park Phase 2, ' +
                        'High-tech Zone, Hefei, China'
                }
            ]
        }
    ],
    weChat: 'WeChat',
    license: 'https://zzlz.gsxt.gov.cn/businessCheck/' +
        'verifKey.do?showType=p&serial=913401003950868800-' +
        'SAIC_CHECK_1000209134010039508688001565264171320&' +
        'signData=MEQCIBUT8c2YdkNRSUfEcMg5qYRmLLgKCtLkCAy1rdTEXYUEAiDq+' +
        'hznRDOO7RxoBY8apoQyrr+TKoRl22tg0w9uVj+v7Q==',
    record: 'https://beian.miit.gov.cn',
    business: [
        'Electronic Business Licence',
        '© 2014-2021 Anhui TsingLink Information Technology Co., Ltd copyright',
        '皖B2-20150070-1',
        'Number',
        'Website Map',
        'Disclaimer'
    ],
    link: {
        title: 'Blogroll：',
        list: [
            {
                title: 'China Security Protocol Network',
                href: 'http://xh.21csp.com.cn/'
            },
            {
                title: 'China Security Industry Network',
                href: 'http://www.21csp.com.cn/'
            },
            {
                title: 'Anhui Provincial Security Association',
                href: 'http://www.aspia.cn/'
            },
            {
                title: 'CPS Chinese Web',
                href: 'http://www.cps.com.cn/'
            },
            {
                title: 'China Security Exhibition Network',
                href: 'http://www.afzhan.com/'
            },
            {
                title: 'Made-in-China',
                href: 'https://www.made-in-china.com/'
            }
        ]
    }
};
export default message;
