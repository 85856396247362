var message = {
    account: 'Account',
    password: 'Password',
    search: 'Search',
    type: 'DeviceType',
    id: 'DeviceID',
    time: 'SignTime',
    status: 'MaintainStatus',
    searching: 'Searching ...',
    noData: 'No data'
};
export default message;
