var message = {
    productCenter: 'PRODUCT CENTER',
    industryApplication: 'INDUSTRY APPLICATIONS',
    applicationIntroduce: 'AI + IoT to create high-quality and ' +
        'differentiated video innovative product solutions',
    pressCenter: 'PRESS CENTER',
    aboutUs: 'ABOUT US',
    companyIntroduce: 'Tsinglink is formed by a team with many years of ' +
        'security industry application experience and the same values. ' +
        'With unique and profound technology in the field of mobile wireless video, ' +
        'as well as the persistent pursuit of new concepts, new technologies and new models, ' +
        'Tsinglink has rapidly grown into an excellent mobile internet security ' +
        'solution and security operation service provider at home and abroad',
    companyProfile: 'Company Profile',
    companyCulture: 'Company Culture',
    developmentHistory: 'Development History'
};
export default message;
