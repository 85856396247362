var message = {
    inputPlaceholder: '请输入内容~',
    selectPlaceholder: '请选择~',
    none: '没有找到~',
    categoryMap1: '产品中心',
    categoryMap2: '行业应用',
    categoryMap3: '新闻中心',
    categoryMap4: '下载专区'
};
export default message;
