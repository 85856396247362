var message = {
    productCenter: '产品中心',
    subProductCenter: 'Product Center',
    industryApplication: '行业应用',
    subIndustryApplication: 'Industry Application',
    applicationIntroduce: '人工智能+物联网 打造高品质差异化的视频创新产品方案',
    pressCenter: '新闻中心',
    subPressCenter: 'Press Center',
    aboutUs: '关于我们',
    subAboutUs: 'About Us',
    companyIntroduce: '清新互联是由一支有着多年安防行业应用经验、' +
        '有着相同价值观的团队。凭借在移动无线视频领域十余载深厚的技术沉淀，' +
        '以及对新理念、新技术、新模式的执着追求，' +
        '企业快速成长为国内外优秀的移动互联安防解决方案和安全运营服务提供商。',
    companyProfile: '公司简介',
    companyCulture: '企业文化',
    developmentHistory: '发展历程'
};
export default message;
