export default [
    {
        path: '/:lang/search',
        name: 'search',
        meta: {
            title: '搜索'
        },
        component: function () { return import('@/views/Search.vue'); }
    }
];
