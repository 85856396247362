var message = {
    inputPlaceholder: 'Please enter the content ~',
    selectPlaceholder: 'please choose ~',
    none: 'could not find it ~',
    categoryMap1: 'Product Center',
    categoryMap2: 'Industry Application',
    categoryMap3: 'Press Center',
    categoryMap4: 'Downloads'
};
export default message;
