var message = {
    title: '关于我们',
    subTitle: 'About Us',
    society: '社会招聘',
    campus: '校园招聘',
    job: '职位名称',
    place: '工作地点',
    date: '发布日期'
};
export default message;
