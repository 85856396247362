import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var Bottom = (function (_super) {
    __extends(Bottom, _super);
    function Bottom() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Bottom.prototype.backTop = function () {
        document.body.scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: 'start'
        });
    };
    Bottom = __decorate([
        Component
    ], Bottom);
    return Bottom;
}(Vue));
export default Bottom;
