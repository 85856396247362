var message = {
    department: '部门',
    nature: '职位性质',
    place: '工作地点',
    number: '招聘人数',
    apply: '立刻申请',
    duty: '岗位职责',
    requirement: '岗位要求',
    benefits: '相关福利'
};
export default message;
