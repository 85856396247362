import { __assign } from "tslib";
import request from '@/utils/axios';
import { publicModule } from '@/store/modules/public';
export function getCompanyContact(params) {
    return request({
        method: 'get',
        url: 'aboutus/company/contact',
        params: __assign(__assign({}, params), { lang: publicModule.lang })
    }).then(function (result) {
        if (result.data.code === 0) {
            return result.data;
        }
        return null;
    });
}
export function getCompanyInfo(params) {
    return request({
        method: 'get',
        url: 'aboutus/company/info',
        params: __assign(__assign({}, params), { lang: publicModule.lang })
    }).then(function (result) {
        if (result.data.code === 0) {
            return result.data;
        }
        return null;
    });
}
