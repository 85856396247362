var message = {
    options: {
        features: '产品特点',
        specifications: '技术规格',
        applications: '组网应用',
        related: '选配信息',
        brochure: '相关手册'
    },
    recommend: '产品推荐',
    subRecommend: 'Product recommendation'
};
export default message;
