var message = {
    title: '关于我们',
    subTitle: 'About Us',
    contactUs: '联系我们',
    welcome: '欢迎您浏览清新互联官网，如果您遇到的问题,请在下方寻找对应的联系方式',
    headquarter: '公司总部',
    contactsType: '联系方式',
    contact: '联络处',
    linkman: '联系人',
    phone: '电话',
    email: '总 部 邮 箱',
    emailP: '邮箱',
    switchboard: '总         机',
    addressP: '总部地址',
    address: '地址',
    marketingHotline: '营销热线',
    marketingHotline2: '营 销 中 心',
    technicalservice: '技术支撑服务',
    afterSalesService: '售后维修服务',
    titleTel: '总机：0551-65725390',
    mapTitle: '安徽清新互联信息科技有限公司'
};
export default message;
