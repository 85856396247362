import home from './home';
import bottom from './bottom';
import productCenter from './productCenter';
import productDetails from './productDetails';
import industryApplication from './industryApplication';
import industryViewBox from './industryViewBox';
import serviceSupport from './serviceSupprot';
import newsCenter from './newsCenter';
import newsList from './newsList';
import newsDetails from './newsDetails';
import aboutUs from './aboutUs';
import customHead from './customHead';
import productSlide from './productSlide';
import customerService from './customerService';
import complaintAdvice from './complaintAdvice';
import maintainQuery from './maintainQuery';
import creditList from './creditList';
import videoList from './videoList';
import contactInfo from './contactInfo';
import recruitInfo from './recruitInfo';
import jobInfo from './jobInfo';
import jobApply from './jobApply';
import search from './search';
var cn = {
    home: home,
    bottom: bottom,
    industryApplication: industryApplication,
    industryViewBox: industryViewBox,
    productCenter: productCenter,
    productDetails: productDetails,
    serviceSupport: serviceSupport,
    newsCenter: newsCenter,
    newsList: newsList,
    newsDetails: newsDetails,
    aboutUs: aboutUs,
    customHead: customHead,
    productSlide: productSlide,
    customerService: customerService,
    complaintAdvice: complaintAdvice,
    maintainQuery: maintainQuery,
    creditList: creditList,
    videoList: videoList,
    contactInfo: contactInfo,
    recruitInfo: recruitInfo,
    jobInfo: jobInfo,
    jobApply: jobApply,
    search: search
};
export default cn;
