export default [
    {
        path: '/:lang/about',
        name: 'aboutus',
        props: true,
        meta: {
            title: '关于我们'
        },
        component: function () { return import('@/views/AboutUs.vue'); }
    },
    {
        path: '/:lang/about/credit/:id',
        name: 'credit',
        props: true,
        meta: {
            title: '荣誉资质'
        },
        component: function () { return import('@/views/CreditList.vue'); }
    },
    {
        path: '/:lang/about/video',
        name: 'video',
        props: true,
        meta: {
            title: '企业视频'
        },
        component: function () { return import('@/views/VideoList.vue'); }
    },
    {
        path: '/:lang/about/recruit/:id',
        name: 'recruit',
        props: true,
        meta: {
            title: '招聘信息'
        },
        component: function () { return import('@/views/RecruitInfo.vue'); }
    },
    {
        path: '/:lang/about/recruit/job/:id',
        name: 'job',
        props: true,
        meta: {
            title: '招聘职位'
        },
        component: function () { return import('@/views/JobInfo.vue'); }
    },
    {
        path: '/:lang/about/recruit/job/apply/:id',
        name: 'apply',
        props: true,
        meta: {
            title: '职位申请'
        },
        component: function () { return import('@/views/JobApply.vue'); }
    },
    {
        path: '/:lang/about/contact/:id',
        name: 'contact',
        props: true,
        meta: {
            title: '联系我们'
        },
        component: function () { return import('@/views/ContactInfo.vue'); }
    }
];
