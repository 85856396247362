var message = {
    options: [
        {
            title: '基本情况',
            options: [
                {
                    label: '类别',
                    name: 'type',
                    options: [
                        {
                            label: '经销商',
                            value: '经销商'
                        },
                        {
                            label: '工程商',
                            value: '工程商'
                        },
                        {
                            label: '最终用户',
                            value: '最终用户'
                        },
                        {
                            label: '其他用户',
                            value: '其他用户'
                        }
                    ]
                },
                {
                    label: '单位名称',
                    name: 'department'
                },
                {
                    label: '所在城市',
                    name: 'city'
                },
                {
                    label: '联系人',
                    name: 'linkman'
                }
            ]
        },
        {
            title: '您的联系方式',
            options: [
                {
                    label: '手机',
                    name: 'mobile'
                },
                {
                    label: '电话',
                    name: 'telephone'
                },
                {
                    label: '邮箱',
                    name: 'email'
                },
                {
                    label: 'Skype',
                    name: 'skype'
                },
                {
                    label: 'QQ',
                    name: 'qq',
                    hint: '为方便我们对您的意见进行跟踪反馈，请务必填写真实有效的联系方式'
                }
            ]
        },
        {
            title: '问题',
            options: [
                {
                    label: '发生问题的产品',
                    name: 'product',
                    options: [
                        {
                            label: '硬盘录象机',
                            value: '硬盘录象机'
                        },
                        {
                            label: '视频服务器',
                            value: '视频服务器'
                        },
                        {
                            label: '板卡',
                            value: '板卡'
                        },
                        {
                            label: '摄像机',
                            value: '摄像机'
                        },
                        {
                            label: '球机',
                            value: '球机'
                        },
                        {
                            label: '集中存储',
                            value: '集中存储'
                        },
                        {
                            label: '键盘',
                            value: '键盘'
                        },
                        {
                            label: '其他',
                            value: '其他'
                        }
                    ]
                },
                {
                    label: '型号',
                    name: 'model'
                }
            ]
        },
        {
            title: '分类',
            options: [
                {
                    label: '开箱问题',
                    name: 'problem',
                    options: [
                        {
                            label: '性能问题',
                            value: '性能问题'
                        },
                        {
                            label: '外观问题',
                            value: '外观问题'
                        },
                        {
                            label: '配件问题',
                            value: '配件问题'
                        }
                    ]
                }
            ]
        },
        {
            title: '情况描述',
            options: [
                {
                    label: '订单编号',
                    name: 'hw_order'
                },
                {
                    label: '数量',
                    name: 'hw_number'
                },
                {
                    label: '检测数量',
                    name: 'hw_check_number'
                },
                {
                    label: '不良品数',
                    name: 'hw_bad_number'
                },
                {
                    label: '型号',
                    name: 'hw_model'
                },
                {
                    label: '序列号',
                    name: 'hw_series_number'
                },
                {
                    label: '问题具体描述',
                    name: 'hw_description',
                    hint: '请尽可详细，性能问题需描述产品内置配件和外接设备信息参数等'
                },
                {
                    label: '处理意见',
                    name: 'hw_opinion',
                    hint: '要求回复日期，意见等'
                },
                {
                    label: '使用中的问题',
                    name: 'hw_question',
                    options: [
                        {
                            label: '性能问题',
                            value: '性能问题'
                        },
                        {
                            label: '外观问题',
                            value: '外观问题'
                        }
                    ]
                }
            ]
        },
        {
            title: '问题描述',
            options: [
                {
                    label: '型号',
                    name: 'sw_model'
                },
                {
                    label: '序列号',
                    name: 'sw_series_number'
                },
                {
                    label: '启始用日期',
                    name: 'sw_use_date'
                },
                {
                    label: '问题产生日期',
                    name: 'sw_bad_date'
                },
                {
                    label: '软件版本号',
                    name: 'sw_version'
                },
                {
                    label: '使用环境',
                    name: 'sw_environment'
                },
                {
                    label: '问题具体描述',
                    name: 'sw_description',
                    hint: '请尽可详细，性能问题需描述产品内置配件和外接设备信息参数等'
                },
                {
                    label: '处理意见',
                    name: 'sw_opinion',
                    hint: '要求回复日期， 意见等'
                }
            ]
        }
    ],
    submit: '提交',
    reset: '重置',
    message: '该输入项未必填项',
    uploading: '上传中...',
    uploadSuccess: '上传成功',
    backspace: "不能包含空格",
    mobile: "手机号格式不正确",
};
export default message;
