export default [
    {
        path: '/:lang/app/:id',
        name: 'app',
        props: true,
        meta: {
            title: '行业应用'
        },
        component: function () { return import('@/views/IndustryApplication.vue'); }
    },
    {
        path: '/:lang/app/detail/:id',
        name: 'appDetails',
        props: true,
        meta: {
            title: '行业应用详情'
        },
        component: function () { return import('@/views/IndustryDetails.vue'); }
    }
];
