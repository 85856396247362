export default [
    {
        path: '/:lang/home',
        name: 'home',
        meta: {
            title: '首页'
        },
        component: function () { return import('@/views/Home.vue'); }
    }
];
