var message = {
    title: 'About Us',
    contactUs: 'contactUs',
    welcome: 'Welcome to the Tsinglink website. ' +
        'If you have any problems, please find the corresponding contact information below',
    headquarter: 'Headquarter',
    contactsType: 'Contact Us',
    switchboard: 'Switchboard',
    emailP: 'Email',
    addressP: 'Address',
    marketingHotline: 'Marketing hotline',
    technicalservice: 'Technical service',
    afterSalesService: 'After-sales service',
    titleTel: 'TEL：0551-65725390'
};
export default message;
