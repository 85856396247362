import { __decorate, __extends, __read } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { publicModule } from '@/store/modules/public';
import { systemModule } from '@/store/modules/system';
var Navigator = (function (_super) {
    __extends(Navigator, _super);
    function Navigator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pageYOffset = 0;
        _this.showTag = false;
        _this.inputValue = '';
        return _this;
    }
    Navigator.prototype.mounted = function () {
        this.scrollEvent();
        window.addEventListener('scroll', this.scrollEvent);
        systemModule.getNavList();
    };
    Navigator.prototype.destroyed = function () {
        window.removeEventListener('scroll', this.scrollEvent);
    };
    Object.defineProperty(Navigator.prototype, "lang", {
        get: function () {
            return publicModule.lang;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Navigator.prototype, "navList", {
        get: function () {
            return systemModule.navList;
        },
        enumerable: false,
        configurable: true
    });
    Navigator.prototype.getRouterOne = function (item) {
        return {
            name: item.alias,
            params: { id: item.children[0].id }
        };
    };
    Navigator.prototype.getRouterTwo = function (item, obj) {
        var _a, _b, _c;
        var name = item.alias;
        var id = obj.id;
        if (item.alias === 'news') {
            name = item.alias + "List";
        }
        if (item.alias === 'aboutus') {
            if (obj.id === 26) {
                name = 'credit';
                (_a = __read(obj.children, 1), id = _a[0].id);
            }
            if (obj.id === 27) {
                name = 'recruit';
                (_b = __read(obj.children, 1), id = _b[0].id);
            }
            if (obj.id === 28) {
                name = 'contact';
                (_c = __read(obj.children, 1), id = _c[0].id);
            }
        }
        return { name: name, params: { id: id } };
    };
    Navigator.prototype.getRouterThree = function (item, obj, data) {
        var name = item.alias;
        var id = 0;
        var hash = '';
        if (item.alias === 'product' || item.alias === 'app') {
            name = item.alias + "Details";
            (id = data.id);
        }
        if (item.alias === 'service') {
            name = item.alias;
            (id = obj.id);
            hash = "#" + data.id;
        }
        if (item.alias === 'aboutus') {
            if (obj.id === 25) {
                name = item.alias;
                hash = "#" + data.id;
            }
            if (obj.id === 26) {
                name = 'credit';
                (id = data.id);
            }
            if (obj.id === 27) {
                name = 'recruit';
                (id = data.id);
            }
            if (obj.id === 28) {
                name = 'contact';
                (id = data.id);
            }
        }
        return { name: name, params: { id: id }, hash: hash };
    };
    Navigator.prototype.scrollEvent = function () {
        this.pageYOffset = window.pageYOffset;
    };
    Navigator.prototype.toggleShowTag = function () {
        this.showTag = !this.showTag;
        this.inputValue = null;
    };
    Navigator.prototype.search = function () {
        this.$router.push({ name: 'search', query: { value: this.inputValue } });
        this.showTag = !this.showTag;
    };
    Navigator = __decorate([
        Component
    ], Navigator);
    return Navigator;
}(Vue));
export default Navigator;
