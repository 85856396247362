import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { publicModule } from '@/store/modules/public';
var Contact = (function (_super) {
    __extends(Contact, _super);
    function Contact() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Contact.prototype, "lang", {
        get: function () {
            return publicModule.lang;
        },
        enumerable: false,
        configurable: true
    });
    Contact.prototype.goTop = function () {
        document.documentElement.scrollTop = 0;
    };
    Contact = __decorate([
        Component
    ], Contact);
    return Contact;
}(Vue));
export default Contact;
