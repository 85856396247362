export default [
    {
        path: '/:lang/service/:id',
        name: 'service',
        props: true,
        meta: {
            title: '服务支持'
        },
        component: function () { return import('@/views/ServiceSupport.vue'); }
    }
];
