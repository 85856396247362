export default [
    {
        path: '/:lang/disclaimer',
        name: 'disclaimer',
        meta: {
            title: '免责声明'
        },
        component: function () { return import('@/views/Disclaimer.vue'); }
    },
    {
        path: '/:lang/sitemap',
        name: 'sitemap',
        meta: {
            title: '网站地图'
        },
        component: function () { return import('@/views/SiteMap.vue'); }
    }
];
