var message = {
    title: 'Wireless intelligent vision products and system solutions provider',
    watch: 'Learn more',
    play: 'Brand video',
    companyName: 'Anhui TsingLink Information Technology Co., Ltd',
    learnMore: 'Learn more',
    companyCulture: 'Corporate Culture',
    mission: 'mission',
    orientation: 'orientation',
    values: 'values',
    culture: 'culture',
    developmentHistory: 'Development History',
    honoraryCertificate: 'Honorary Certificate',
    copyright: 'Copyright',
    patentCertificate: 'Patent Certificate',
    systemCertificate: 'Enterprise Certificate',
    enterpriseHonor: 'Enterprise Honor',
    productTesting: 'Product Testing',
    brandImage: 'Brand Image',
    subBrandImage: 'Learn more',
    contactUs: 'Contact Us',
    subContactUs: 'Learn more',
    partners: 'Partners',
    subPartners: 'Learn more'
};
export default message;
