var message = {
    options: [
        {
            title: 'Basic Information',
            options: [
                {
                    label: 'Category',
                    name: 'type',
                    options: [
                        {
                            label: 'Dealer',
                            value: '经销商'
                        },
                        {
                            label: 'Project Contractor',
                            value: '工程商'
                        },
                        {
                            label: 'Final User',
                            value: '最终用户'
                        },
                        {
                            label: 'Other User',
                            value: '其他用户'
                        }
                    ]
                },
                {
                    label: 'Department',
                    name: 'department'
                },
                {
                    label: 'City',
                    name: 'city'
                },
                {
                    label: 'Contact',
                    name: 'linkman'
                }
            ]
        },
        {
            title: 'Contact Information',
            options: [
                {
                    label: 'Mobile',
                    name: 'mobile'
                },
                {
                    label: 'Telephone',
                    name: 'telephone'
                },
                {
                    label: 'Email',
                    name: 'email'
                },
                {
                    label: 'Skype',
                    name: 'skype'
                },
                {
                    label: 'QQ',
                    name: 'qq',
                    hint: 'Please be sure to fill in the real and valid contact information'
                }
            ]
        },
        {
            title: 'Problem',
            options: [
                {
                    label: 'Product',
                    name: 'product',
                    options: [
                        {
                            label: 'DVR',
                            value: '硬盘录象机'
                        },
                        {
                            label: 'Video Server',
                            value: '视频服务器'
                        },
                        {
                            label: 'Board Bard',
                            value: '板卡'
                        },
                        {
                            label: 'Camera',
                            value: '摄像机'
                        },
                        {
                            label: 'Ballhead Camera',
                            value: '球机'
                        },
                        {
                            label: 'Consolidated Storage',
                            value: '集中存储'
                        },
                        {
                            label: 'Keyboard',
                            value: '键盘'
                        },
                        {
                            label: 'Other',
                            value: '其他'
                        }
                    ]
                },
                {
                    label: 'Model',
                    name: 'model'
                }
            ]
        },
        {
            title: 'Type',
            options: [
                {
                    label: 'Question',
                    name: 'problem',
                    options: [
                        {
                            label: 'Performance Problem',
                            value: '性能问题'
                        },
                        {
                            label: 'Appearance Problem',
                            value: '外观问题'
                        },
                        {
                            label: 'Fitting Problem',
                            value: '配件问题'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Situation Description',
            options: [
                {
                    label: 'Order Number',
                    name: 'hw_order'
                },
                {
                    label: 'Quantity',
                    name: 'hw_number'
                },
                {
                    label: 'Check Quantity',
                    name: 'hw_check_number'
                },
                {
                    label: 'Defective Quantity',
                    name: 'hw_bad_number'
                },
                {
                    label: 'Model',
                    name: 'hw_model'
                },
                {
                    label: 'Series Number',
                    name: 'hw_series_number'
                },
                {
                    label: 'Problem Description',
                    name: 'hw_description',
                    hint: 'Performance issues need to describe the ' +
                        'product\'s built-in accessories and external device information parameters'
                },
                {
                    label: 'Suggestion',
                    name: 'hw_opinion',
                    hint: 'Request reply date, opinion, etc.'
                },
                {
                    label: 'Problems In Use',
                    name: 'hw_question',
                    options: [
                        {
                            label: 'Performance Problem',
                            value: '性能问题'
                        },
                        {
                            label: 'Service Problem',
                            value: '外观问题'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Problem Description',
            options: [
                {
                    label: 'Model',
                    name: 'sw_model'
                },
                {
                    label: 'Series Number',
                    name: 'sw_series_number'
                },
                {
                    label: 'Commencement Date',
                    name: 'sw_use_date'
                },
                {
                    label: 'Issue Date',
                    name: 'sw_bad_date'
                },
                {
                    label: 'Software Version',
                    name: 'sw_version'
                },
                {
                    label: 'Environment',
                    name: 'sw_environment'
                },
                {
                    label: 'Problem Description',
                    name: 'sw_description',
                    hint: 'Performance issues need to describe the ' +
                        'product\'s built-in accessories and external device information parameters'
                },
                {
                    label: 'Suggestion',
                    name: 'sw_opinion',
                    hint: 'Request reply date, opinion, etc.'
                }
            ]
        }
    ],
    submit: 'Submit',
    reset: 'Reset',
    message: 'This field is required',
    uploading: 'Uploading...',
    uploadSuccess: 'Upload success',
    backspace: 'Cannot contain Spaces',
    mobile: 'The phone format is incorrect'
};
export default message;
