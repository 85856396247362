import { __decorate, __extends } from "tslib";
import { Module, Mutation, Action, getModule, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { getCategoryTree } from '@/api/system/category';
import { getAdvertising } from '@/api/system/advertising';
import { getLogo } from '@/api/system/logo';
import { getWebsiteIntroduce, getWebsiteSeo, getWebsiteDisclaimer } from '@/api/system/website';
var System = (function (_super) {
    __extends(System, _super);
    function System() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.navList = [];
        _this.advertisingList = [];
        _this.introduceList = [];
        _this.logo = {};
        _this.seo = {};
        _this.disclaimer = {};
        return _this;
    }
    System.prototype.serNavList = function (val) {
        this.navList = val;
    };
    System.prototype.setAdvertisingList = function (val) {
        this.advertisingList = val;
    };
    System.prototype.setIntroduceList = function (val) {
        this.introduceList = val;
    };
    System.prototype.setLogo = function (val) {
        this.logo = val;
    };
    System.prototype.setSEO = function (val) {
        this.seo = val;
    };
    System.prototype.setDisclaimer = function (val) {
        this.disclaimer = val;
    };
    System.prototype.getNavList = function () {
        var _this = this;
        return getCategoryTree({
            visible: 1,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            _this.serNavList(result.rows);
            return result;
        }).
            catch(function (err) { return err; });
    };
    System.prototype.getAdvertisingList = function () {
        var _this = this;
        return getAdvertising({
            page: 1,
            rows: 10,
            visible: 1,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            _this.setAdvertisingList(result.rows);
            return result;
        }).
            catch(function (err) { return err; });
    };
    System.prototype.getIntroduceList = function () {
        var _this = this;
        return getWebsiteIntroduce({}).then(function (result) {
            var list = result.rows.map(function (item) {
                item.value = JSON.parse(item.value);
                return item;
            });
            _this.setIntroduceList(list);
            return list;
        }).
            catch(function (err) { return err; });
    };
    System.prototype.getLogo = function () {
        var _this = this;
        return getLogo({
            page: 1,
            rows: 999
        }).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                obj[item.id] = item;
            });
            _this.setLogo(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    System.prototype.getSEO = function () {
        var _this = this;
        return getWebsiteSeo({}).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                obj[item.key] = item;
            });
            _this.setSEO(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    System.prototype.getDisclaimer = function () {
        var _this = this;
        return getWebsiteDisclaimer({}).then(function (result) {
            _this.setDisclaimer(result.rows[0]);
            return result.rwos[0];
        }).
            catch(function (err) { return err; });
    };
    __decorate([
        Mutation
    ], System.prototype, "serNavList", null);
    __decorate([
        Mutation
    ], System.prototype, "setAdvertisingList", null);
    __decorate([
        Mutation
    ], System.prototype, "setIntroduceList", null);
    __decorate([
        Mutation
    ], System.prototype, "setLogo", null);
    __decorate([
        Mutation
    ], System.prototype, "setSEO", null);
    __decorate([
        Mutation
    ], System.prototype, "setDisclaimer", null);
    __decorate([
        Action
    ], System.prototype, "getNavList", null);
    __decorate([
        Action
    ], System.prototype, "getAdvertisingList", null);
    __decorate([
        Action
    ], System.prototype, "getIntroduceList", null);
    __decorate([
        Action
    ], System.prototype, "getLogo", null);
    __decorate([
        Action
    ], System.prototype, "getSEO", null);
    __decorate([
        Action
    ], System.prototype, "getDisclaimer", null);
    System = __decorate([
        Module({ name: 'system', namespaced: true, dynamic: true, store: store })
    ], System);
    return System;
}(VuexModule));
export default System;
export var systemModule = getModule(System);
