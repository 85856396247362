var message = {
    account: '账号',
    password: '密码',
    search: '查询',
    type: '设备型号',
    id: '设备ID',
    time: '签收时间',
    status: '维修状态',
    searching: '查询中 ...',
    noData: '暂无数据'
};
export default message;
