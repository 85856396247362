import { __decorate, __extends } from "tslib";
import { Module, Mutation, Action, getModule, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { getProduct } from '@/api/details/product';
import { getApp } from '@/api/details/app';
import { getNews } from '@/api/details/news';
import { getManuals } from '@/api/details/manuals';
import { getSearch } from '@/api/details/search';
var Details = (function (_super) {
    __extends(Details, _super);
    function Details() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.productID = 6;
        _this.productObj = {};
        _this.productParams = {};
        _this.productList = [];
        _this.productTotal = 0;
        _this.productRecommend = [];
        _this.applicationID = 12;
        _this.applicationObj = {};
        _this.applicationParams = {};
        _this.applicationList = [];
        _this.applicationTotal = 0;
        _this.newsID = 20;
        _this.newsObj = {};
        _this.newsParams = {};
        _this.newsStick = [];
        _this.newsStickList = [];
        _this.newsList = [];
        _this.newsTotal = 0;
        _this.searchList = [];
        _this.searchTotal = 0;
        return _this;
    }
    Details.prototype.setProductID = function (val) {
        this.productID = val;
    };
    Details.prototype.setProductObj = function (val) {
        this.productObj = val;
    };
    Details.prototype.setProductParams = function (val) {
        this.productParams = val;
    };
    Details.prototype.setProductList = function (val) {
        this.productList = val;
    };
    Details.prototype.setProductTotal = function (val) {
        this.productTotal = val;
    };
    Details.prototype.setProductRecommend = function (val) {
        this.productRecommend = val;
    };
    Details.prototype.setApplicationID = function (val) {
        this.applicationID = val;
    };
    Details.prototype.setApplicationObj = function (val) {
        this.applicationObj = val;
    };
    Details.prototype.setApplicationParams = function (val) {
        this.applicationParams = val;
    };
    Details.prototype.setApplicationList = function (val) {
        this.applicationList = val;
    };
    Details.prototype.setApplicationTotal = function (val) {
        this.applicationTotal = val;
    };
    Details.prototype.setNewsID = function (val) {
        this.newsID = val;
    };
    Details.prototype.setNewsObj = function (val) {
        this.newsObj = val;
    };
    Details.prototype.setNewsParams = function (val) {
        this.newsParams = val;
    };
    Details.prototype.setNewsStick = function (val) {
        this.newsStick = val;
    };
    Details.prototype.setNewsStickList = function (val) {
        var newsStickListTem = [];
        val.forEach(function (item) {
            if (item.stick === 1) {
                newsStickListTem.push(item);
            }
        });
        this.newsStickList = newsStickListTem;
    };
    Details.prototype.setNewsList = function (val) {
        this.newsList = val;
    };
    Details.prototype.setNewsTotal = function (val) {
        this.newsTotal = val;
    };
    Details.prototype.setSearchList = function (val) {
        console.log(val);
        this.searchList = val;
    };
    Details.prototype.setSearchTotal = function (val) {
        this.searchTotal = val;
    };
    Details.prototype.getProductObj = function (id) {
        var _this = this;
        return getProduct({
            id: id,
            visible: 1
        }).then(function (result) {
            _this.setProductObj(result.rows[0]);
            return result.rows[0];
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getProductParams = function () {
        var _this = this;
        return getProduct({
            page: 1,
            rows: 999,
            visible: 1,
            show: 1,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                if (obj[item.category_id]) {
                    obj[item.category_id].push(item);
                }
                else {
                    obj[item.category_id] = [item];
                }
            });
            _this.setProductParams(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getProductList = function (_a) {
        var _this = this;
        var page = _a.page, rows = _a.rows, id = _a.id;
        return getProduct({
            page: page,
            rows: rows,
            visible: 1,
            category_id: id,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            _this.setProductList(result.rows);
            _this.setProductTotal(result.total);
            return result;
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getProductRecommend = function () {
        var _this = this;
        return getProduct({
            page: 1,
            rows: 10,
            visible: 1,
            recommend: 1,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            _this.setProductRecommend(result.rows);
            return result;
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getProductManuals = function (id) {
        return getManuals({
            page: 1,
            rows: 999,
            product_id: id,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) { return result.rows; }).
            catch(function (err) { return err; });
    };
    Details.prototype.getApplicationObj = function (id) {
        var _this = this;
        return getApp({
            id: id,
            visible: 1
        }).then(function (result) {
            _this.setApplicationObj(result.rows[0]);
            return result.rows[0];
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getApplicationParams = function () {
        var _this = this;
        return getApp({
            page: 1,
            rows: 999,
            visible: 1,
            show: 1,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                if (obj[item.category_id]) {
                    obj[item.category_id].push(item);
                }
                else {
                    obj[item.category_id] = [item];
                }
            });
            _this.setApplicationParams(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getApplicationList = function (_a) {
        var _this = this;
        var page = _a.page, rows = _a.rows, id = _a.id;
        return getApp({
            page: page,
            rows: rows,
            visible: 1,
            category_id: id,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            _this.setApplicationList(result.rows);
            _this.setApplicationTotal(result.total);
            return result;
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getNewsObj = function (id) {
        var _this = this;
        return getNews({
            id: id,
            visible: 1
        }).then(function (result) {
            _this.setNewsObj(result.rows[0]);
            return result.rows[0];
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getNewsParams = function () {
        var _this = this;
        return getNews({
            page: 1,
            rows: 4,
            visible: 1,
            show: 1,
            sort: 'show_sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            var obj = {};
            result.rows.forEach(function (item) {
                if (obj[item.category_id]) {
                    obj[item.category_id].push(item);
                }
                else {
                    obj[item.category_id] = [item];
                }
            });
            _this.setNewsParams(obj);
            return obj;
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getNewsList = function (_a) {
        var _this = this;
        var page = _a.page, rows = _a.rows, id = _a.id, stick = _a.stick;
        return getNews({
            page: page,
            rows: rows,
            stick: stick,
            visible: 1,
            category_id: id,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            if (stick) {
                _this.setNewsStick(result.rows);
                return result;
            }
            _this.setNewsStickList(result.rows);
            _this.setNewsList(result.rows);
            _this.setNewsTotal(result.total);
            return result;
        }).
            catch(function (err) { return err; });
    };
    Details.prototype.getSearchList = function (_a) {
        var _this = this;
        var page = _a.page, rows = _a.rows, description = _a.description, category_id = _a.category_id;
        return getSearch({
            page: page,
            rows: rows,
            description: description,
            category_id: category_id,
            sort: 'sort,id',
            order: 'desc,asc'
        }).then(function (result) {
            console.log(result);
            _this.setSearchList(result.rows);
            return result;
        }).
            catch(function (err) { return err; });
    };
    __decorate([
        Mutation
    ], Details.prototype, "setProductID", null);
    __decorate([
        Mutation
    ], Details.prototype, "setProductObj", null);
    __decorate([
        Mutation
    ], Details.prototype, "setProductParams", null);
    __decorate([
        Mutation
    ], Details.prototype, "setProductList", null);
    __decorate([
        Mutation
    ], Details.prototype, "setProductTotal", null);
    __decorate([
        Mutation
    ], Details.prototype, "setProductRecommend", null);
    __decorate([
        Mutation
    ], Details.prototype, "setApplicationID", null);
    __decorate([
        Mutation
    ], Details.prototype, "setApplicationObj", null);
    __decorate([
        Mutation
    ], Details.prototype, "setApplicationParams", null);
    __decorate([
        Mutation
    ], Details.prototype, "setApplicationList", null);
    __decorate([
        Mutation
    ], Details.prototype, "setApplicationTotal", null);
    __decorate([
        Mutation
    ], Details.prototype, "setNewsID", null);
    __decorate([
        Mutation
    ], Details.prototype, "setNewsObj", null);
    __decorate([
        Mutation
    ], Details.prototype, "setNewsParams", null);
    __decorate([
        Mutation
    ], Details.prototype, "setNewsStick", null);
    __decorate([
        Mutation
    ], Details.prototype, "setNewsStickList", null);
    __decorate([
        Mutation
    ], Details.prototype, "setNewsList", null);
    __decorate([
        Mutation
    ], Details.prototype, "setNewsTotal", null);
    __decorate([
        Mutation
    ], Details.prototype, "setSearchList", null);
    __decorate([
        Mutation
    ], Details.prototype, "setSearchTotal", null);
    __decorate([
        Action
    ], Details.prototype, "getProductObj", null);
    __decorate([
        Action
    ], Details.prototype, "getProductParams", null);
    __decorate([
        Action
    ], Details.prototype, "getProductList", null);
    __decorate([
        Action
    ], Details.prototype, "getProductRecommend", null);
    __decorate([
        Action
    ], Details.prototype, "getProductManuals", null);
    __decorate([
        Action
    ], Details.prototype, "getApplicationObj", null);
    __decorate([
        Action
    ], Details.prototype, "getApplicationParams", null);
    __decorate([
        Action
    ], Details.prototype, "getApplicationList", null);
    __decorate([
        Action
    ], Details.prototype, "getNewsObj", null);
    __decorate([
        Action
    ], Details.prototype, "getNewsParams", null);
    __decorate([
        Action
    ], Details.prototype, "getNewsList", null);
    __decorate([
        Action
    ], Details.prototype, "getSearchList", null);
    Details = __decorate([
        Module({ name: 'details', namespaced: true, dynamic: true, store: store })
    ], Details);
    return Details;
}(VuexModule));
export default Details;
export var detailsModule = getModule(Details);
