export default [
    {
        path: '/:lang/product/:id',
        name: 'product',
        props: true,
        meta: {
            title: '产品中心'
        },
        component: function () { return import('@/views/ProductCenter.vue'); }
    },
    {
        path: '/:lang/product/detail/:id',
        name: 'productDetails',
        props: true,
        meta: {
            title: '产品中心详情'
        },
        component: function () { return import('@/views/ProductDetails.vue'); }
    }
];
