import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Contact from '@/components/Contact.vue';
import Bottom from '@/components/Bottom.vue';
import Navigator from '@/components/Navigator.vue';
import { publicModule } from '@/store/modules/public';
import { systemModule } from '@/store/modules/system';
var Header = (function (_super) {
    __extends(Header, _super);
    function Header() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Header.prototype.mounted = function () {
        this.resizeEvent();
        window.addEventListener('resize', this.resizeEvent);
        systemModule.getLogo();
        systemModule.getSEO();
    };
    Header.prototype.beforeDestroy = function () {
        window.removeEventListener('resize', this.resizeEvent);
    };
    Object.defineProperty(Header.prototype, "seo", {
        get: function () {
            return systemModule.seo;
        },
        enumerable: false,
        configurable: true
    });
    Header.prototype.resizeEvent = function () {
        publicModule.setInnerWidth(window.innerWidth);
        publicModule.setInnerHeight(window.innerHeight);
    };
    Header = __decorate([
        Component({
            components: { Contact: Contact, Navigator: Navigator, Bottom: Bottom },
            metaInfo: function () {
                return {
                    title: this.seo.seo_title ? this.seo.seo_title.value : '',
                    meta: [
                        {
                            name: 'keywords',
                            content: this.seo.seo_title ? this.seo.seo_keywords.value : ''
                        },
                        {
                            name: 'description',
                            content: this.seo.seo_title ? this.seo.seo_description.value : ''
                        }
                    ]
                };
            }
        })
    ], Header);
    return Header;
}(Vue));
export default Header;
